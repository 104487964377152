import { useAuthStore } from '../store/auth';
import axios from './axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

export const login = async (username, password) => {
    try {
        const { data, status } = await axios.post('/token/', {
            username,
            password,
        });
        if (status === 200) {
            setAuthUser(data.access, data.refresh);
        }
        return { data, error: null };
    } catch (error) {
        return {
            data: null,
            error: error.response.data?.detail || 'Something went wrong',
        };
    }
};

export const register = async (username, password, password2, email, refral) => {
    try {
        const { data } = await axios.post('/register/', {
            username,
            password,
            password2,
            email,
            refral
        });
        await login(username, password);
        // window.location.href = "/verify"
        return { data, error: null };
    } catch (error) {
        return {
            data: null,
            error: error.response.data || 'Something went wrong',
        };
    }
};

export const logout = () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    useAuthStore.getState().setUser(null);

};

export const setUser = async () => {
    // ON PAGE LOAD
    try {
        const accessToken = Cookies.get('access_token');
        const refreshToken = Cookies.get('refresh_token');
        if (!accessToken || !refreshToken) {
            return;
        }
        if (isAccessTokenExpired(accessToken)) {
            const response = await getRefreshToken(refreshToken);
            setAuthUser(response.access, response.refresh);
        } else {
            setAuthUser(accessToken, refreshToken);
        }
    } catch (error) {
        console.log(error)

    }
};

export const setAuthUser = (access_token, refresh_token) => {
    Cookies.set('access_token', access_token, {
        expires: 1,
        secure: true,
    });

    Cookies.set('refresh_token', refresh_token, {
        expires: 7,
        secure: true,
    });

    const user = jwt_decode(access_token) ?? null;

    if (user) {
        useAuthStore.getState().setUser(user);
    }
    useAuthStore.getState().setLoading(false);
};

export const getRefreshToken = async () => {
    try {
        const refresh_token = Cookies.get('refresh_token');
        const response = await axios.post('/token/refresh/', {
            refresh: refresh_token,
        });
        return response.data;
    } catch (error) {
    }
};

export const isAccessTokenExpired = (accessToken) => {
    try {
        const decodedToken = jwt_decode(accessToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true; // Token is invalid or expired
    }
};
