import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import Tabbar from '../components/tabbar/Tabbar';
import PrivateRoute from './PrivateRoute';
const MainLayout = () => {
    const loggedIn = useAuthStore((state) => state.isLoggedIn)();
    const navigate=useNavigate()
    useEffect(()=>{
    if (loggedIn) {
       navigate('/user/home')
    }
    },[])
    return (
        <>
        <PrivateRoute>
            <Navbar/>
            <Outlet />
            <Tabbar/>
        </PrivateRoute>
        </>
    );
}

export default MainLayout;
