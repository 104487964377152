import React, { useState } from 'react';
import './naavbar.css'
import { Link } from 'react-router-dom';
import { BsFillSendArrowDownFill } from "react-icons/bs";
import useAxios from '../../utils/useAxios';
import { useAuthStore } from '../../store/auth';
const Navbar = () => {
    const [balance, setBalance] = useState(0)
    const [profile, setProfile] = useState('')
    const [wallet, setWallet] = useState('')
    const [userName, setUsername] = useState('')
    const api = useAxios()
    const [bnb, setBnb] = useState('')
    const [loading, setLoadingBal] = useState(false)
    const [polygon, setPolygon] = useState('')
    const [Limit, setLimit] = useState(0)
    const rpc_polygon = "https://polygon-mainnet.g.alchemy.com/v2/S30ZpRpeJpogqbaZMV33NTlELXTVwcTQ"
    const rpc_bnb = "https://small-wider-lake.bsc.discover.quiknode.pro/ec7c5fa19861c143537ab3bb58544ae9fcf65237/"
    const loggedIn = useAuthStore((state) => state.isLoggedIn)();









    return (
        <nav className='navbar-b'>
            <div>
                <Link to="/user/profile" className='tab-link'>
                    <img className='logo' style={{ width: 50, height: 50, border: "1px solid white", borderRadius: '50%' }} src='/logoapp.png' alt="" />
                    <span style={{ marginInline: '10px' }}>FDS</span>
                </Link>
            </div>
            <div className='Balance' style={{ fontSize: 20, padding: 7, border: "none", borderRadius: '20px', fontWeight: 600 }}>
                <Link to="/user/send/">
                    <BsFillSendArrowDownFill size={25} color='blue' />
                </Link>
            </div>
        </nav>
    );
}

export default Navbar;
