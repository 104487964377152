import axios from 'axios';
import { getRefreshToken, isAccessTokenExpired, setAuthUser } from './auth';
import { API_BASE_URL } from './constants';
import Cookies from 'js-cookie';

const useAxios = () => {
    const accessToken = Cookies.get('access_token');
    const refreshToken = Cookies.get('refresh_token');

    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: { Authorization: `Bearer ${accessToken}` },
    });

    // Request interceptor
    axiosInstance.interceptors.request.use(async (req) => {
        if (!isAccessTokenExpired(accessToken)) return req;

        try {
            const response = await getRefreshToken(refreshToken);
            setAuthUser(response.access, response.refresh);
            req.headers.Authorization = `Bearer ${response?.data?.access}`;
        } catch (error) {
            // Handle error when refreshing token
            console.error('Error refreshing token:', error);
            // Optionally, you may want to redirect the user to the login page or show a message
        }

        return req;
    });

    // Response interceptor
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    // Handle unauthorized access (e.g., redirect to login)
                    console.error('Unauthorized access:', error);
                    // Optionally, you may want to redirect the user to the login page or show a message
                } else {
                    // Handle other types of HTTP errors
                    console.error('Request error:', error);
                    // Optionally, you may want to show a generic error message to the user
                }
            } else {
                // Handle unexpected errors (e.g., network issues, timeouts)
                console.error('Unexpected error:', error);
                // Optionally, you may want to show a generic error message to the user
            }

            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxios;
