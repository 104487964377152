import React, { useEffect, useState } from 'react';
import './dash.css'
import { Userbalance } from '../../utils/balance';
import { Link } from 'react-router-dom'
import { History } from '../../utils/history';
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from 'react-hot-toast';
import { UserProfile } from '../../utils/Userprofile';
// import { CircularProgressbar } from 'react-circular-progressbar';
const Neodash = () => {
    const [balance, setBalance] = useState(0)
    const [withdraw, setwithDraw] = useState(0)
    const [revnue, setRevnue] = useState(0)
    const [todayrevnue, setToadayRevnue] = useState(0)
    const [wallet, setWallet] = useState('')
    const [showwallet, setShowallet] = useState('')
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [orderId, setOrderId] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [username, setUsername] = useState('')
    const api = useAxios()
    const [history, setHistory] = useState([])
    const [profile, setProfile] = useState('')
    const [addNew, setAddNew] = useState(3)
    const getBalance = async () => {
        let data = await Userbalance()
        setBalance(data.balance)
        setRevnue(data.earning)
        setwithDraw(data.withdraw)
        setToadayRevnue(data.today_earning)
        const hdata = await History()
        let userData = await UserProfile()
        setProfile(userData.profile_photo)
        setUsername(userData.username)
        setHistory(hdata)
        try {
            const data = await api.get(`${base_url}/wallet/`)
            const originalString = data.data.wallet
            const lastTwoElements = originalString.slice(-3);
            const fisrt_elem = originalString.slice(0, 4)
            setShowallet(`${fisrt_elem}`)
            setWallet(originalString)
        } catch (error) {
        }

    }
    const changewallet = async () => {
        try {
            const data = await api.post(`${base_url}/wallet/`, { wallet: wallet })
            toast.success("wallet changed successfully")
            setShow(false)

            const originalString = wallet
            const lastTwoElements = originalString.slice(-3);
            const fisrt_elem = originalString.slice(0, 4)
            setShowallet(`${fisrt_elem}...${lastTwoElements}`)
            setWallet(originalString)
        } catch (error) {
            toast.error("error while changin g wallet")
        }
    }
    const addUser = async () => {
        try {
            const data = await api.post(`${base_url}/add_users/`, { username: wallet })
            toast.success("user added successfully")
            setShow(false)
            const originalString = wallet
            const lastTwoElements = originalString.slice(-3);
            const fisrt_elem = originalString.slice(0, 4)
            setShowallet(`${fisrt_elem}...${lastTwoElements}`)
            await getBalance()
            setWallet(originalString)
        } catch (error) {
            toast.error("Check balance or retry")
        }
    }
    useEffect(() => {
        getBalance()
    }, [])
    return (
        <div className="container-dashm">
            <div className="card">
                <div className="profile">
                    <div className="image">
                        <img src={profile} alt="person" />
                    </div>
                    <p style={{ color: "black" }}>@<strong>{username}</strong></p>
                    <div className="balance">
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1680531/sphere.jpg" alt="ball" />
                        <p>FDS BALANCE</p>
                        <h3>${balance}</h3>
                        {/* <span>395910.87USD</span> */}
                        <div className="refresh">
                            {/*?xml version="1.0" encoding="iso-8859-1"?*/}
                            {/* Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 458.186 458.186" style={{ enableBackground: 'new 0 0 458.186 458.186', fill: '#fff' }} xmlSpace="preserve">
                                <g>
                                    <g>
                                        <path d="M445.651,201.95c-1.485-9.308-10.235-15.649-19.543-14.164c-9.308,1.485-15.649,10.235-14.164,19.543
          c0.016,0.102,0.033,0.203,0.051,0.304c17.38,102.311-51.47,199.339-153.781,216.719c-102.311,17.38-199.339-51.47-216.719-153.781
          S92.966,71.232,195.276,53.852c62.919-10.688,126.962,11.29,170.059,58.361l-75.605,25.19
          c-8.944,2.976-13.781,12.638-10.806,21.582c0.001,0.002,0.002,0.005,0.003,0.007c2.976,8.944,12.638,13.781,21.582,10.806
          c0.003-0.001,0.005-0.002,0.007-0.002l102.4-34.133c6.972-2.322,11.675-8.847,11.674-16.196v-102.4
          C414.59,7.641,406.949,0,397.523,0s-17.067,7.641-17.067,17.067v62.344C292.564-4.185,153.545-0.702,69.949,87.19
          s-80.114,226.911,7.779,310.508s226.911,80.114,310.508-7.779C435.905,339.799,457.179,270.152,445.651,201.95z" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="features">
                    {history.length <= 0 ?
                        <p className='text-center' style={{ color: "black" }}>No txs found yet</p> :
                        <>
                            {history.map((i, j) => {
                                return (
                                    <div className="block-info" key={j}>
                                        <div className="block-image">
                                            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1680531/money.png" alt="burger" />
                                        </div>
                                        <div className="block-txt" style={{ color: "black" }}>
                                            <p style={{ color: "black" }}>{i.type}</p>
                                            <span>{i.date?.slice(2, 10)}</span>
                                        </div>
                                        <div className="block-price">
                                            <p className={i.type == "withdrawal" ? "minus" : "plus"}>{i.type == "withdrawal" ? "-" : "+"}${i.amount}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                    
                </div>
                <button className="mybutton" onClick={() => {
                    handleShow()
                }}>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="70%" height="70%" viewBox="0 0 44.238 44.238" style={{ enableBackground: 'new 0 0 44.238 44.238', fill: 'white', transform: 'scale(1) rotate(45deg)' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <path d="M22.119,44.237C9.922,44.237,0,34.315,0,22.12C0,9.924,9.922,0.001,22.119,0.001S44.238,9.923,44.238,22.12
          S34.314,44.237,22.119,44.237z M22.119,1.501C10.75,1.501,1.5,10.751,1.5,22.12s9.25,20.619,20.619,20.619
          s20.619-9.25,20.619-20.619S33.488,1.501,22.119,1.501z" />
                                <g>
                                    <path d="M31.434,22.869H12.805c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h18.628c0.414,0,0.75,0.336,0.75,0.75
              S31.848,22.869,31.434,22.869z" />
                                </g>
                                <g>
                                    <path d="M22.119,32.183c-0.414,0-0.75-0.336-0.75-0.75V12.806c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v18.626
              C22.869,31.847,22.533,32.183,22.119,32.183z" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div>
                <Modal open={show} onClose={handleClose} center>
                    <div className='' style={{}}>
                        <div style={{ marginTop: 25, borderRadius: "4px" }}>
                            <div>
                                <div className="form-w" style={{ width: "100%" }}>
                                    <div className='d-flex justify-content-center' >
                                        <div className={`custom-box custom-box-left ${addNew == 1 && "active-box"}`} onClick={() => setAddNew(1)}>+ User</div>
                                        <div className={`custom-box custom-box-left ${addNew == 2 && "active-box"}`} onClick={() => setAddNew(2)}>Wallet</div>
                                    </div>
                                    {addNew == 1 ? <>
                                        <div >
                                            <div style={{ width: "100%", fontSize: '11px' }}>
                                                <p className='text-center'>{wallet}</p>
                                            </div>
                                            <p className='text-warning' style={{ fontSize: '11px' }}>
                                                Add user direcly paying from you wallet
                                            </p>
                                        </div>
                                        <div className="input-w">
                                            <div className="inputBox-w">
                                                <input type="string" placeholder="add user name" onChange={(e) => {
                                                    setWallet(e.target.value)
                                                }} />
                                            </div>
                                            <div className="inputBox-w">
                                                <input type="submit" defaultValue="Submit" onClick={(e) => {
                                                    addUser()
                                                }} />
                                            </div>
                                        </div>
                                    </> : addNew == 2 ?
                                        <>
                                            <div >
                                                <div style={{ width: "100%", fontSize: '11px' }}>
                                                    <p className='text-center'>{wallet}</p>
                                                </div>
                                                <p className='text-warning' style={{ fontSize: '11px' }}>
                                                    Add bnb or Polygon wallet only and you will
                                                    recieve On one network
                                                </p>
                                            </div>
                                            <div className="input-w">
                                                <div className="inputBox-w">
                                                    <input type="string" placeholder="Change withdraw wallet" onChange={(e) => {
                                                        setWallet(e.target.value)
                                                    }} />
                                                </div>
                                                <div className="inputBox-w">
                                                    <input type="submit" defaultValue="Submit" onClick={(e) => {
                                                        changewallet()
                                                    }} />
                                                </div>
                                            </div>
                                        </> : <></>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                <Toaster />
            </div>
        </div>



    );
}

export default Neodash;
