import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import { base_url } from '../config/config';
import useAxios from '../../utils/useAxios';
import { logout } from '../../utils/auth';
// import MainWrapper from './layouts/MainWrapper';
const Header = () => {
    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user,
    ]);
    const api = useAxios()
    const toggleNavbar = () => {
        const navbar = document.querySelector("[data-navbar]");
        const navTogglers = document.querySelectorAll("[data-nav-toggler]");
        const overlay = document.querySelector("[data-overlay]");
        navbar.classList.toggle("active");
        overlay.classList.toggle("active");
        document.body.classList.toggle("active");



    }
    useState(() => {



    }, [])
    return (
        <header className="header" data-header>
            <div className="container">
                <a href="#" className="logo">
                    <img src="./assets/images/logoapp.png" style={{ height: 60, width: 60, borderRadius: "50%", border: '2px solid white' }} alt="Cryptoza home" />
                </a>
                <nav className="navbar" data-navbar>
                    <div className="navbar-top">
                        <a href="#" className="logo">
                            <img src="/logoapp.png" width={185} height={31} alt="Cryptoza home" />
                        </a>
                        <button className="nav-close-btn" aria-label="close menu" data-nav-toggler onClick={toggleNavbar}>
                            <ion-icon name="close-outline" aria-hidden="true" />
                        </button>
                    </div>
                    <ul className="navbar-list">
                        <li>
                            <a href="#" className="navbar-link">Home</a>
                        </li>

                        {isLoggedIn() ?

                            <>
                                <li>
                                    <Link to="/user/home" className="navbar-link">Dashboard</Link>
                                </li>
                                <li>
                                    <a to="#" className="navbar-link" onClick={logout}>Logout</a>
                                </li>
                            </>
                            : <>

                                <li>
                                    <Link href="/" className="navbar-link">Login</Link>
                                </li>
                                <li>
                                    <Link href="/register" className="navbar-link">Register</Link>
                                </li>
                            </>}
                    </ul>
                </nav>
                <div className="btn-group">
                    {!isLoggedIn() &&
                        <>
                            <Link to="/" className="btn ghost-btn">Sign In</Link>
                            <Link to="/register" className="btn btn-primary">Get Started</Link>
                        </>
                    }
                </div>
                <button className="nav-open-btn" aria-label="open menu" data-nav-toggler onClick={toggleNavbar}>
                    <ion-icon name="menu-outline" aria-hidden="true" />
                </button>
                <div className="overlay" data-overlay data-nav-toggler onClick={toggleNavbar} />
            </div>
        </header>

    );
}

export default Header;
