import React, { useEffect, useState } from 'react';
import './tababr.css'
import { Link } from 'react-router-dom';
import { logout } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { SiHomebridge } from "react-icons/si";
import { FaUserCheck } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";
import { FaPowerOff } from "react-icons/fa";
const tabItems = [
    { icon: <HiOutlineBanknotes size={25} />, view: 2, label: 'Deposit' },
    { icon: <FaHandHoldingDollar size={25} />, view: 3, label: 'Withdraw' },
    { icon: <RiTeamFill size={25} />, view: 4, label: 'Team' },
    { icon: <FaUserCheck size={20} />, view: 5, label: 'Profile' },
    { icon: <SiHomebridge size={25} />, view: 1, label: 'Home' },
    { icon: <MdOutlineSupportAgent size={25} />, view: 6, label: 'Support' },
    { icon: <FaPowerOff size={25} />, view: 7, label: 'Logout' },

];
const Tabbar = () => {
    const navigate = useNavigate()
    const url = window.location.pathname
    const [view, setView] = useState(0)
    useEffect(() => {
        let url = window.location.pathname;

        // Set view based on the URL
        const matchedTab = tabItems.find(item => url === `/user/${item.label.toLowerCase()}`);
        if (matchedTab) {
            setView(matchedTab.view);
        }
    }, [url]);
    return (

        <div style={styles.bottomNav}>
            {tabItems.map((item,j) => (
            
                    <div key={j} style={styles.navItem}>
                        {item.label == 'Logout' ? <>
                            <a className="tab-link" href="#">
                                <div className={`tab-link ${view === item.view ? 'active-tab' : ''}`} onClick={(e) => {
                                e.preventDefault()
                                logout()
                                navigate('/')
                                }} >
                                    {item.icon}
                                </div>
                                {view === item.view && <p className='text-center'>{item.label}</p>}
                            </a>
                        </> :
                            <>
                                <Link className="tab-link" to={`/user/${item.label.toLowerCase()}`}>
                                    <div className={`tab-link ${view === item.view ? 'active-tab' : ''}`} onClick={() => setView(item.view)} >
                                        {item.icon}
                                    </div>
                                    {view === item.view && <p className='text-center'>{item.label}</p>}
                                </Link>

                            </>

                        }
                    </div>
               
            ))}


        </div>
    );
};

const styles = {
    bottomNav: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: '#333',
        color: 'white',
        padding: '10px',
        height: '70px'
    },
    navItem: {
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
        fontSize: '12px',

    },
};
export default Tabbar;
