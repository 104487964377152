import React, { useState, useEffect } from 'react';
import './Login.css'
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import { login } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
const Login = () => {
    const [sigUp, SetSignup] = useState(false)
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/user/home');
        }

    }, []);

    const resetForm = () => {
        setUsername('');
        setPassword('');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const { error } = await login(username, password);
        if (error) {
            alert(error);
        } else {
            navigate('/user/home');
            resetForm();
            // window.location.reload();
        }
    };
    return (
        <>
            <Header />
            <div id="container-login">
                <div id="title">
                    <i className="material-icons lock">lock</i> Login
                </div>
                <form>
                    <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                        <div className="input-addon">
                            <i className="material-icons">face</i>
                        </div>
                        <div>
                            <input id="username" placeholder="Username" type="text" required className="validate input-cs" onChange={(e) => {
                                setUsername(e.target.value)
                            }} autoComplete="off" style={{ marginLeft: '10px' }} />
                        </div>
                    </div>
                    <div className="clearfix" />
                    <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                        <div className="input-addon">
                            <i className="material-icons">vpn_key</i>
                        </div>
                        <div>
                            <input id="password" placeholder="Password" type="password" required className="validate input-cs" autoComplete="off" onChange={(e) => {
                                setPassword(e.target.value)
                            }} style={{ marginLeft: '10px' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="remember-me" style={{ display: 'flex', justifyContent: 'start' }}>
                            <div>
                                <input type="checkbox" className='input-cs' />
                            </div>
                            <div><span style={{ color: '#DDD' }}>Remember Me</span></div>
                        </div>
                    </div>
                    <input type="submit" className='input-cs' defaultValue="Log In" style={{ height: 35, width: 100, fontSize: 12 }} onClick={(e) => {
                        handleLogin(e)
                    }} />
                </form>
                <div style={{ display: 'flex', justifyContent: 'center', marginInline: '20px', marginTop: 20 }}>
                    {/* <div>
                        <a href="#">Forgot?</a>
                    </div> */}
                    <div  >
                        <Link className='text-success' to="/register" style={{ textAlign: 'end' }}>Register?</Link>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Login;
