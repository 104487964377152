import React, { useEffect, useState } from 'react';
import './trade.css'
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
import { Userbalance } from '../../utils/balance';
import toast, { Toaster } from 'react-hot-toast';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Detail from '../detail/Detail';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Trade = () => {
    const [data, setData] = useState([])
    const api = useAxios()
    const [view, setView] = useState(2)
    const [inprogress, setInprogress] = useState(true)
    const [tradeData, setTradeData] = useState([])
    const [tloading, setTloading] = useState(false)
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [orderId, setOrderId] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [userData,setUserData]=useState({})
    const [amount, setAmount] = useState(0)
    const [penndingOrder, setPendingOrders] = useState([])
    const [endingHour, setEndinghour] = useState(0)
    const [compltedOrder, setCompltedOrders] = useState([])
    const [orderdata, setOrderdata] = useState(null)
    const [balance, setBalance] = useState(0)
    const [remaBalance, setRemBalance] = useState('')
    const [showAmount, setShowAmount] = useState('')
    const [disable, setDisable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [expectedComnission, setExxpectedCommmssion] = useState(0)
    const [minAmount, setMinAmount] = useState(0)
    const [maxAmount, setMaxAmount] = useState(0)
    const [commisionPercent, setCommissioPercent] = useState(0)
    const [level, setLevel] = useState(1)


    const getTrades = async () => {
        try {
            let response = await api.get(`${base_url}/trades`)
            setData(response.data)
        } catch (error) {

        }
    }
    const fetchBalance = async () => {
        let data = await Userbalance()
        setBalance(data.balance)

    }
    function getTimeStamps(days) {
        var currentTime = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
        var futureTime = currentTime + (days * 24 * 60 * 60); // Add hours to current timestamp

        return {
            currentTimestamp: currentTime,
            futureTimestamp: futureTime
        };
    }
    let createOrder = async () => {
        const currentTimestamp = getTimeStamps(0).currentTimestamp; // Get current timestamp
        const futureTimestamp = getTimeStamps(endingHour).futureTimestamp; // Get future timestamp

        setLoading(true)

        const data = {
            // Replace with the actual user ID
            trade_id: orderId,
            amount: parseFloat(amount), // Replace with the actual commission value from the backend
            starting_time: currentTimestamp,
            ending_time: futureTimestamp
        };
        try {
            const response = await api.post(`${base_url}/create_order/`, data);
            handleClose();
            toast.success('order created succesfully')
            setDisable(false)
            setLoading(false)
            fetchBalance()
            setRemBalance(0)
            setShowAmount('')
        } catch (error) {
            setLoading(false)
            console.error(error);
            toast.error("server error")
            setRemBalance(0)
            setShowAmount('')
        }

    };
    const getPending = async () => {
        try {

            let resp = api.get(`${base_url}/pending_order_list/`)
            let dta = (await resp).data
            setPendingOrders(dta)
        } catch (error) {
        }
    }
    const getCompleted = async () => {

        try {

            let resp = api.get(`${base_url}/completed_orders_list/`)
            let dta = (await resp).data
            setCompltedOrders(dta)

        } catch (error) {
            toast.error("server error")
        }
    }
    

    const calulate_rem_balance = (e) => {
        const rem_bal = parseFloat(balance) - parseFloat(e.target.value)
        if (e.target.value !== '' || parseFloat(e.target.value) < minAmount) {
            let commission = parseFloat(e.target.value) * (parseFloat(commisionPercent) / 100)
            setShowAmount(e.target.value)
            setRemBalance(rem_bal.toFixed(2))
            setExxpectedCommmssion(commission)

        } else {
            setShowAmount(0)
            setRemBalance(0)
        }
        if (rem_bal < 0) {
            setDisable(true)
            setRemBalance(0)
        }
        else {
            setDisable(false)
        }
    }

    function calculateTimePercentage(startTimestamp, endTimestamp) {
        const currentTimestamp = Math.floor(Date.now() / 1000); // Get current Unix timestamp in seconds
        const elapsedSeconds = currentTimestamp - startTimestamp;
        const totalSeconds = endTimestamp - startTimestamp;
        const percentageCompleted = (elapsedSeconds / totalSeconds) * 100;
        const roundedPercentage = percentageCompleted.toFixed(2); // Round to two decimal places
        return roundedPercentage;
    }
    const get_time = (time) => {
        // Assuming you have a Unix timestamp
        const unixTimestamp = time * 1000;
        const dateObject = new Date(unixTimestamp);
        // Example timestamp
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format
        };
        const formattedDateTime = dateObject.toLocaleString(undefined, options);

        return formattedDateTime// Output: "8/6/2021" (format may vary based on locale)

    }
    

    useEffect(() => {
        getTrades()
        fetchBalance()
        setLevel(1)
    }, [])
    return (
        <div style={{ marginTop: 100 }}>
            <div className='d-flex justify-content-between header-div'>
                <div>
                    <a href="#" className={level === 1 ? 'custom-btn btn-4 td-none' : 'td-none'} onClick={(e) => {
                        e.preventDefault()
                        setLevel(1)
                        getTrades()
                    }}>PLANS</a>
                </div>
                <div>
                    <a href="#" className={level === 3 ? 'custom-btn btn-4 td-none' : 'td-none'} onClick={(e) => {
                        e.preventDefault()
                        setLevel(3)
                        getCompleted()

                    }}>COMPLETED</a>

                </div>
                <div>
                    <a href="#" className={level === 2 ? 'custom-btn btn-4 td-none' : 'td-none'} onClick={(e) => {
                        e.preventDefault()
                        setLevel(2)
                        getPending()
                    }}>PENDING</a>

                </div>
            </div>
            <div className="main" style={{ marginBottom: 100 }}>
                <ul className="cards">
                    {level === 1 ?
                        <>
                            {data.map((i, j) => {

                                return (
                                    <li className="cards_item" key={j}>
                                        <div className="card-t">
                                            <div className="card_content">
                                                <h2 className="card_title">{i.titel}</h2>
                                                <p className="card_text">Min Amount is {i.min_amount}$ max amount is {i.max_amount}$ smart coin selection </p>
                                                <p className="card_text">Commission is {i.percentage}% </p>
                                                <p className="card_text">Time Span is {i.time_span} Hours </p>
                                                <button className="btn card_btn" onClick={(e) => {
                                                    e.preventDefault()
                                                    setOrderId(i?.id)
                                                    setEndinghour(i.time_span)
                                                    setMinAmount(i.min_amount)
                                                    setMaxAmount(i.max_amount)
                                                    setCommissioPercent(i.percentage)
                                                    handleShow()
                                                }}>Buy</button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </> : level === 2 ?

                            <>
                                {penndingOrder.map((i, j) => {

                                    return (
                                        <li className="cards_item" key={j}>
                                            <div className="card-t">
                                                <div className="card_content">
                                                    <p className="card_text"> {i.amount?.toFixed(2)}$ is invested by you an you will get {i.commission?.toFixed(4)}$ Reward smart coin selection </p>
                                                    <p className="card_text">Commission is {i.commission?.toFixed(2)}% </p>
                                                    <div className='card-text' style={{ height: 100, width: 100 }}>
                                                        <CircularProgressbar value={calculateTimePercentage(i.starting_time, i.ending_time)} text={`${calculateTimePercentage(i.starting_time, i.ending_time)}%`} />
                                                    </div>
                                                    <p className='text-white'>Time Span Details</p>
                                                    <p className='text-white'> From {get_time(i.starting_time)} to {get_time(i.ending_time)}</p>

                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </> :

                            <>
                                {compltedOrder.map((i, j) => {
                                    return (
                                        <li className="cards_item" key={j}>
                                            <div className="card-t">
                                                <div className="card_content">
                                                    <h2 className="card_title">{i.titel}</h2>
                                                    <p className="card_text"> {i.amount?.toFixed(2)}$ is invested by you an you  got {i.commission?.toFixed(4)}$ Reward smart coin selection </p>
                                                    <p className="card_text">Commission is {i.commission?.toFixed(2)}% </p>
                                                    <p className='text-white'>Time Span Details</p>
                                                    <p className='text-white'> started on {get_time(i.starting_time)} and ended {get_time(i.ending_time)}</p>
                                                    <button className="btn card_btn" onClick={(e) => {
                                                        e.preventDefault()
                                                        handleShow1()
                                                        setOrderdata(compltedOrder[j])
                                                    }}>DETAILS</button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </>
                    }
                </ul>
            </div>
            <Toaster />
            <div>
                <Modal open={show} onClose={handleClose} center styles={{ borderRadius: '20px !important', border: '1px solid black' }}>
                    <div className='d-flex justify-content-center'>
                        <div className="form-w">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p>BALANCE</p>
                                </div>
                                <div>
                                    <p>{balance.toFixed(3)}$</p>
                                </div>
                            </div>
                            <div className="input-w">
                                <div className="inputBox-w">
                                    <input type="number" placeholder="Enter Amount" onChange={(e) => {
                                        setAmount(e.target.value)
                                        calulate_rem_balance(e)
                                    }} />
                                </div>
                                <div className="inputBox-w">
                                    <input type="submit" defaultValue="Submit" onClick={createOrder} disabled={disable} />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                                <div>
                                    <p>AMOUNT</p>
                                </div>
                                <div>
                                    <p className='text-danger'>-{showAmount}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                                <div>
                                    <p>REVNUE</p>
                                </div>
                                <div>
                                    <p className='text-success'>{expectedComnission?.toFixed(4)}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                                <div>
                                    <p>REMAINDER</p>
                                </div>
                                <div>
                                    <p className='text-success'>{remaBalance}$</p>
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between my-1">
                                <div>
                                    <p className='text-danger'>Min: <b>{minAmount}</b>USDT</p>
                                </div>
                                <div>
                                    <p className='text-success'>Max:<b>{maxAmount}</b> USDT</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
            </div>
            <div>
                <Modal open={show1} onClose={handleClose1} center styles={{ borderRadius: '20px !important', border: '1px solid black' }}>
                    <Detail order={orderdata} />
                </Modal>
            </div>
        </div>

    );
}

export default Trade;
