import React, { useEffect, useState } from 'react';
import './team.css';
import Table from '../home/Table';
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
import { Userbalance } from '../../utils/balance';
import Modal from 'react-responsive-modal';
import Summary from './Summary';
import { FaUsersRectangle, FaUsersViewfinder } from "react-icons/fa6";
import { AiOutlineDollar } from "react-icons/ai";
import toast, { Toaster } from 'react-hot-toast';
import Copy from '../copy/Copy';
const Team = () => {
    const [team, setTeam] = useState([]);
    const [level, setLevel] = useState(1);
    const [adata, setAdata] = useState(null);
    const api = useAxios();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [userData, setUserData] = useState({});
    const handleShow = () => setShow(true);
    const [levelTotal, setLevelTotal] = useState(0);
    const [baladat, setBaladata] = useState({});
    const [claimed, setClaimed] = useState(false)

    const getBalance = async () => {
        const data = await Userbalance();
        setBaladata(data);
    };

    const getTeam = async () => {
        try {
            const data = await api.get(`${base_url}/get_team/`);
            const baladata = await Userbalance();
            setAdata(data.data);
            setTeam(data.data[`level_1`] || []);
            let lve_total_amount = baladata[`level_${1}_total`] || 0
            let lev_Totalusers = data.data[`level_1`]?.length || 0;
            let requiredusers = Math.floor(6 * Math.pow(6, 1) / 6);
            let remaining = requiredusers - lev_Totalusers
            setLevelTotal(<LevelTotal totalAmount={lve_total_amount} users={lev_Totalusers} remaining={remaining} level={1} setClaimed={setClaimed} />);
        } catch (error) {
            setAdata({});
            setTeam([]);
            setLevelTotal(<LevelTotal totalAmount={0} users={0} remaining={0} level={0} />);
        }
    };

    const userSummary = async (user) => {
        try {
            const response = await api.get(`${base_url}/summary/${user.user_id}/`);
            const data = response.data;
            setLevelTotal(data[`level_${level}_sum`]);
            setUserData({
                deposit: data.total_deposit,
                commission: data[`level_${level}_sum`],
                username: user.username,
                profile: user.profile_photo,
            });
            handleShow();
        } catch (error) {
        }
    };

    const handleLevelClick = async (selectedLevel) => {
        setLevel(selectedLevel);
        let teamdata = adata[`level_${selectedLevel}`] || []
        setTeam(teamdata);
        let lve_total_amount = baladat[`level_${selectedLevel}_total`] || 0
        let lev_Totalusers = teamdata?.length || 0;
        let requiredusers = Math.floor(6 * Math.pow(6, selectedLevel) / 6);
        let remaining = requiredusers - lev_Totalusers
        setLevelTotal(<LevelTotal totalAmount={lve_total_amount} users={lev_Totalusers} remaining={remaining} level={selectedLevel}  setClaimed={setClaimed} />);

    };

    useEffect(() => {
        setLevel(1);
        getTeam();
        getBalance();
    }, []);

    return (
        <div style={{ marginTop: 100 }}>
            <div className='d-flex justify-content-start header-div' style={{ flexWrap: 'wrap', gap: '20px' }}>
                {[1, 2, 3, 4, 5].map((lvl) => (
                    <div key={lvl}>
                        <a
                            href='#'
                            className={level === lvl ? 'custom-btn btn-4 td-none' : 'td-none'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleLevelClick(lvl);

                            }}
                        >{`LEVEL${lvl}`}</a>
                    </div>
                ))}
                <div>
                    <a
                        href='#'
                        className={level === 'direct' ? 'custom-btn btn-4 td-none' : 'td-none'}
                        onClick={async (e) => {
                            e.preventDefault();
                            setLevel("direct")
                            try {
                                let data = await api.get(`${base_url}/direct_users/`)
                                setTeam(data.data)
                            } catch (error) {
                                setTeam([])
                            }
                        }}
                    >Direct Users</a>
                </div>
            </div>

            {levelTotal}
            <div className='' style={{ marginInline: '5%', marginTop: '20px', }}>
                <div className='datatable-container'>
                    <table className='datatable' style={{ overflow: 'auto', marginBottom: 80 }}>
                        <thead>
                            <tr>
                                <th>Profile</th>
                                <th>User Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody style={{ height: 400, overflow: 'auto' }}>
                            {team.map((user, index) => (
                                <tr key={index}>
                                    <td>
                                        <img src={user.profile_photo} className='profile-img' />
                                    </td>
                                    <td>{user.username} <Copy text={user.username} /></td>
                                    <td>
                                        <div className={`circle-team ${user.active ? 'circle-team-success' : 'circle-team-danger'}`}></div>
                                    </td>
                                    <td>View</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Toaster />
            <Modal open={show} onClose={handleClose} center styles={{ borderRadius: '20px !important', border: '1px solid black' }}>
                <Summary data={userData} />
            </Modal>
        </div>
    );
};

export default Team;


const LevelTotal = ({ users, totalAmount, remaining, level, claimed, setClaimed }) => {
    const api = useAxios()
    const reward = {
        level_1: 2,
        level_2: 10,
        level_3: 40,
        level_4: 100,
        level_5: 500,
    };
    return (
        <div className='header-div' style={{ marginTop: 20, paddingInline: 10 }}>
            <div className='d-flex justify-content-between '>
                <p className='text-white'>
                    <FaUsersRectangle color={"white"} size={15} /> TOTAL
                </p>
                <p className='text-white'>
                    {users}
                </p>
            </div>
            <div className='d-flex justify-content-between '>
                <p className='text-white'>
                    <FaUsersViewfinder color={"white"} size={15} /> REMAINING
                </p>
                <p className='text-white'>
                    {remaining}
                </p>
            </div>
            <div className='d-flex justify-content-between '>
                <p className='text-white'>
                    <AiOutlineDollar color={"white"} size={15} /> AMOUNT
                </p>
                <p className='text-white'>
                    ${totalAmount}
                </p>
            </div>
        </div>
    );
};

